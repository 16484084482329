<script lang="ts">
export interface Card {
  name: string
  author: string
  genres: string[]
  tags: string[]
  annotation: string
  url: string
  imageThumb: string
  imageFull: string
}
</script>

<script lang="ts" setup>
import { computed } from 'vue'
import { useModal } from 'vue-final-modal'
import ImageModal from '@/components/ImageModal.vue'
const props = defineProps<{
  cards: Card[]
}>()

const cardsRandomlySorted = computed(() => [...props.cards].sort(() => Math.random() - 0.5))

function openFullImageModal(imageUrl: string) {
  const { open, close } = useModal({
    component: ImageModal,
    attrs: {
      imageUrl,
      onConfirm() {
        close()
      }
    }
  })
  open()
}

function logMetric() {
  const wi = window as unknown as any
  wi.ym(97831121, 'reachGoal', 'read_link')
}
</script>

<template>
  <div>
    <ul class="cards">
      <li class="card" v-for="(card, index) in cardsRandomlySorted" :key="index">
        <div class="cover">
          <img
            :src="`${card.imageThumb}`"
            :alt="`Книга. ${card.name}. Автор -  ${card.author}`"
            @click="openFullImageModal(card.imageFull)"
          />
          <div class="button-wrapper">
            <a class="read-button" :href="card.url" target="_blank" @click="logMetric">
              Читать онлайн
            </a>
          </div>
        </div>
        <div class="description">
          <h2 class="name">
            {{ card.name }}
          </h2>
          <h3 class="author">{{ card.author }}</h3>
          <div class="genres">
            {{ card.genres.join(', ') }}
          </div>
          <div class="tags">
            <span class="tag" v-for="tag in card.tags" :key="tag">{{ tag }}</span>
          </div>
        </div>
        <p class="annotation" v-html="card.annotation"></p>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.card {
  padding: 1.5rem;
  background: #fff;
  border-radius: 1rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.26);
  display: grid;
  grid-template-columns: 215px 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'co de'
    'co an';
}

// @media (min-width: 992px) {
//   .container {
//     width: 970px;
//   }
// }

// @media (min-width: 768px) {
//   .container {
//     width: 750px;
//   }
// }

.cover {
  grid-area: co;
  // border-radius: 1rem 0 0 1rem;
  img {
    border-radius: 1rem;
    max-height: 100%;
    cursor: pointer;
  }
}

.button-wrapper {
  text-align: center;
  padding-top: 1rem;
}

.read-button {
  background: #cd1b1b;
  color: #fff;
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 0.6rem;
  text-transform: uppercase;
  font-size: 1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background: #d84a4a;
  }
}

.description {
  grid-area: de;
  padding: 0.2rem 1.5rem 0;
}

.name {
  font-size: 1.8rem;
  line-height: 2rem;
  margin-bottom: 0.5rem;
  font-weight: bold;

  a:hover {
    text-decoration: underline;
  }
}

.author {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
}

.genres {
  color: #a50000;
  margin-bottom: 0.4rem;
}

.tags {
  margin-bottom: 0.8rem;
}

.tag {
  background: #de7070;
  color: #fff;
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
}

.annotation {
  grid-area: an;
  font-size: 0.9rem;
  line-height: 1.1rem;
  padding: 0 1.5rem;
}

@media (max-width: 990px) {
  .card {
    padding: 1.2rem;
    height: auto;
    grid-template-columns: 160px 1fr;
    border-radius: 0;
    margin-bottom: 0;
    box-shadow: none;

    & + & {
      border-top: 1px solid #e0e0e0;
    }
  }

  .read-button {
    font-size: 0.9rem;
  }

  .description {
    padding: 0 1rem;
  }

  .name {
    font-size: 1.5rem;
    line-height: 1.8rem;
    margin-bottom: 0.2rem;
  }

  .author {
    font-size: 1.1rem;
    margin-bottom: 0.2rem;
  }

  .genres {
    font-size: 0.9rem;
    line-height: 1.2rem;
    margin-bottom: 0.3rem;
  }

  .tags {
  }

  .tag {
    font-size: 0.7rem;
    padding: 0.1rem 0.4rem;
    margin-right: 0.4rem;
    display: inline-block;
  }

  .cover {
    img {
      border-radius: 0.5rem;
    }
  }
}

@media (max-width: 540px) {
  .card {
    grid-template-columns: 140px 1fr;
    grid-template-areas:
      'co de'
      'an an';
  }

  .tags {
    margin-bottom: 0;
  }

  .annotation {
    padding: 0.8rem 0 0;
  }
  // .description-head {
  //   min-height: 276px;
  // }

  .read-button {
    font-size: 0.7rem;
  }

  // .description-sub {
  //   margin-left: calc(-140px - 1rem);
  // }
}
</style>
