<script lang="ts" setup>
import { ref } from 'vue'
import CardsList from '@/components/CardsList.vue'
import type { Card } from '@/components/CardsList.vue'
import { useHead } from '@unhead/vue'

useHead({
  title: 'Отвергнутая драконом. Литпортал',
  meta: [
    {
      name: 'description',
      content: 'Читать книги по теме Отвергнутая драконом. Литпортал'
    }
  ]
})

const cards = ref<Card[]>([
  {
    name: 'Отвергнутая. (не)желанная невеста',
    author: 'Ольга Иконникова, Юки',
    genres: ['Любовное фэнтези', 'Приключенческое фэнтези'],
    tags: ['от ненависти до любви', 'властный герой'],
    annotation: `
      Жених бросил меня у алтаря, решив, что я его недостойна. Желая избежать позора, отец отослал меня во дворец, и теперь я - фрейлина ее высочества.<br>
      <br>
      Жизнь при дворе полна интриг и козней, и чтобы выжить здесь, мне придется сильно постараться. Но как это сделать, если тот, кто разбил мне сердце, тоже находится тут?<br>
      <br>
      ❤️ В тексте есть:&nbsp;<br>
      - девушка из обедневшего рода и богатый знатный мужчина, не привыкший считаться с чужими чувствами,&nbsp;<br>
      - интриги и тайны при королевском дворе,&nbsp;<br>
      - измена, предательство и настоящая любовь
    `,
    url: 'https://litnet.com/reader/otvergnutaya-nezhelannaya-nevesta-b484063',
    imageThumb: '/covers/thumb/otvergnutaya-nezhelannaya-nevesta.jpg',
    imageFull: '/covers/full/otvergnutaya-nezhelannaya-nevesta.jpg'
  },

  {
    name: 'Бесправная жена дракона',
    author: 'Северина Рэй',
    genres: ['Любовное фэнтези', 'Попаданцы в другие миры'],
    tags: ['дракон', 'жестокий герой'],
    annotation: `
      Умерев в своем мире, я попадаю в тело беременной служанки. Теперь я не бездетная старушка, а мама прекрасной дочери лорда-дракона и в скором времени сына. Но есть проблема. Меня держат в подвале, словно пленницу, а спать мне приходится на тонком соломенном тюке. Роль, которую уготовил мне хозяин – это бесконечные роды, пока я не умру, и единственный путь к свободе – родить ему наследника. Но когда на свет появляется еще одна дочь, мой “муж” берет себе в жены ровню – чистокровную драконицу из родовитой семьи. И теперь мне приходится не только беречь своих детей от гнева госпожи, но и прятать метку истинности, образовавшуюся у меня на кисти сразу после родов. Как выжить в мире драконов, если я – бесправная человечка?
    `,
    url: 'https://litnet.com/reader/bespravnaya-zhena-drakona-b484155',
    imageThumb: '/covers/thumb/bespravnaya-zhena-drakona.jpg',
    imageFull: '/covers/thumb/bespravnaya-zhena-drakona.jpg'
  },

  {
    name: 'Ненужная истинная. Академия Драконов',
    author: 'Ирина Алексеева',
    genres: ['Магическая академия', 'Любовное фэнтези'],
    tags: ['от ненависти до любви', 'встреча через время'],
    annotation: `
      Самодовольная усмешка медленно угасла на красивых губах, и, развернувшись ко мне всем корпусом, парень окинул меня внимательным, оценивающим взглядом. На миг мне показалось, что синие глаза стали совершенно ледяными, когда Райдер, в свою очередь, узнал меня.<br>
      <br>
      — Джай, – протянул дракон глубоким, низким голосом, от которого по моему позвоночнику прошла дрожь. Он правильно расценил причину моего нахождения на территории Академии, и чувственные губы искривились в предвкушающей усмешке. – Кажется, этот год будет интересным.<br>
      _______________________<br>
      <br>
      Боевой факультет Академии Драконов неожиданно свел меня с моим истинным, который от меня отказался и разорвал помолвку. И я бы могла просто игнорировать его, погрузившись в учебу, но случайно купленный на рынке артефакт прочно привязал меня к бывшему жениху. Теперь моя жизнь в его руках.&nbsp;<br>
      <br>
      # Обязательный ХЭ<br>
      # Драконы и магия<br>
      # Противостояние героев<br>
      # Любовь и страсть<br>
    `,
    url: 'https://litnet.com/reader/nenuzhnaya-istinnaya-akademiya-drakonov-b484435',
    imageThumb: '/covers/thumb/nenuzhnaya-istinnaya-akademiya-drakonov.jpg',
    imageFull: '/covers/thumb/nenuzhnaya-istinnaya-akademiya-drakonov.jpg'
  },

  {
    name: 'Отвергнутая. Хозяйка кофейного дома',
    author: 'Катрин Алисина',
    genres: ['Бытовое фэнтези', 'Любовное фэнтези'],
    tags: ['властный герой', 'решительная героиня'],
    annotation: `
      Жених изменил с сестрой. Ровно перед тем, как я оказалась в новом мире. А здесь не лучше! Бывший муж вышвырнул из дома прежнюю хозяйку тела. Вместе с двумя детьми! Прогнал помирать от голода.<br>
      <br>
      Но я-то — не она и так просто не сдамся!<br>
      <br>
      Устрою себе уютный дом, бывшему отомщу и прибыльный бизнес открою. Осталось только решить, чем торговать: редкие книги, грушевый конфитюр или… кофе?&nbsp;<br>
      Поняла! Объединю свои возможности и открою прибыльный кофейный дом!&nbsp;<br>
      <br>
      Но нужно скорее что-то делать с дядей бывшего. Шикарным, но властным мужчиной, драконьим лордом. Который мне теперь просто проходу не дает!<br>
      <br>
      А я занята. У меня бизнес в гору идет!
    `,
    url: 'https://litnet.com/ru/reader/otvergnutaya-xozyaika-kofeinogo-doma-b484542',
    imageThumb: '/covers/thumb/otvergnutaya-xozyaika-kofeinogo-doma.jpg',
    imageFull: '/covers/full/otvergnutaya-xozyaika-kofeinogo-doma.jpg'
  },

  {
    name: 'Отвергнутая, или (не)желанный трофей Дракона',
    author: 'Мари Александер',
    genres: ['Попаданцы в другие миры', 'Бытовое фэнтези'],
    tags: ['попаданка', 'властный дракон'],
    annotation: `
      Порченная! – кричал колдун – Она не понесла! Она не станет матерью дракона!<br>
      - Лорд, это неправда! – повторяла я мужу. – Он лжёт!<br>
      Но дракон меня не слышал, в его глазах отражалась ярость и гнев.<br>
      - Убирайся из моего замка! Я лишаю тебя титула и земель! – оттолкнул меня он. – Стража, за ворота её!<br>
      <br>
      По воле богов я попала в тело Леди Иллирии, хозяйки замка и обширных земель вокруг него. Ради сохранения мира я стала женой дракона-варвара. Но я приняла его как мужа, до освящения союза и вот она расплата. Меня обесчестили, унизили и выгнали из собственного замка.<br>
      <br>
      Дракон думал, что забрал у меня всё?<br>
      Нет! Самое ценное я унесла с собой!<br>
      Но дракон об этом не узнает, ведь он сам отказался от НАС!<br>
    `,
    url: 'https://litnet.com/reader/otvergnutaya-ili-nezhelannyi-trofei-drakona-b484859',
    imageThumb: '/covers/thumb/otvergnutaya-ili-nezhelannyi-trofei-drakona.jpg',
    imageFull: '/covers/thumb/otvergnutaya-ili-nezhelannyi-trofei-drakona.jpg'
  },

  {
    name: 'Отвергнутая истинная Высшего дракона',
    author: 'Аурика Рейн',
    genres: ['Любовное фэнтези', 'Приключенческое фэнтези'],
    tags: ['встреча через время', 'от ненависти до любви'],
    annotation: `
      Я должна была стать женой Священного Дракона и разделить с ним свою силу во благо родной земли. Но он предал меня. Незадолго до свадьбы отравил Светоч тёмной магией и обвинил во всём меня, приговорив к лишению всего, даже имени.&nbsp;<br>
      <br>
      Теперь я – Безымянная. Вынуждена до конца своих дней отрабатывать чужой грех. Светоч гаснет без моей силы. Стихийные бедствия настигают великие земли драконов. И однажды я вновь встречаю Священного Дракона, своего бывшего жениха, вероломно предавшего меня. <br>
      <br>
      Раненого и совершенно меня не узнающего…<br>
      <br>
      # истинная пара<br>
      # драконы<br>
      # встреча через время<br>
      # предательство и интриги<br>
      # тайны прошлого<br>
      # ХЭ
    `,
    url: 'https://litnet.com/reader/otvergnutaya-istinnaya-vysshego-drakona-b484753',
    imageThumb: '/covers/thumb/otvergnutaya-istinnaya-vysshego-drakona.jpg',
    imageFull: '/covers/full/otvergnutaya-istinnaya-vysshego-drakona.jpg'
  },

  {
    name: 'Отвергнутая. Два дракона для попаданки',
    author: 'Деймон Краш',
    genres: ['Любовное фэнтези', 'Приключенческое фэнтези'],
    tags: ['многомужество', 'любовь и страсть'],
    annotation: `
      Я попала в тело принцессы, которая должна была выйти замуж за принцев двух королевств. Один - яркий серебряный дракон, объединяющий людей вокруг себя. Другой - чёрный дракон, умный и скрытный, способный видеть меня насквозь. И в каждом - дикий зверь, готовый к охоте.<br>
      <br>
      Чтобы спасти королевство, за которое я теперь в ответе, а заодно и собственную жизнь, мне придётся укротить обоих.&nbsp;<br>
    `,
    url: 'https://litnet.com/reader/otvergnutaya-dva-drakona-dlya-popadanki-b485179',
    imageThumb: '/covers/thumb/otvergnutaya-dva-drakona-dlya-popadanki.jpg',
    imageFull: '/covers/full/otvergnutaya-dva-drakona-dlya-popadanki.jpg'
  }
])
</script>

<template>
  <div>
    <header class="header">
      <img src="/images/otvergnutaya-header.png" alt="Отвергнутая драконом" />
    </header>
    <CardsList :cards="cards" />
  </div>
</template>

<style scoped>
.header {
  display: flex;
  justify-content: center;
  padding: 1em 1rem 1.5rem;
}
.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.vue:hover {
  filter: drop-shadow(0 0 2em #42b883aa);
}
</style>
