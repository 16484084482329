<script lang="ts" setup>
import { useI18n } from '@/plugins/i18n'

const { t } = useI18n()
</script>

<template>
  <div class="h-full flex flex-col items-center justify-center font-bold">
    <span class="text-dark text-[3.75rem] leading-[4.5rem]">404</span>

    <h1 class="text-gray-800 text-[2.125rem] leading-10">
      {{ t('pages.not_found.title') }}
    </h1>
  </div>
</template>
