<script setup lang="ts">
import { RouterView } from 'vue-router'
import { ModalsContainer } from 'vue-final-modal'
// import AppHeader from './components/AppHeader.vue'
// import AppFooter from './components/AppFooter.vue'
import { ref } from 'vue'
// import { useTitle } from '@vueuse/core'
// import { useI18n } from '@/plugins/i18n'

// const { t } = useI18n()

// useTitle(t('general.title'))

const isFetching = ref(false)
</script>

<template>
  <div>
    <div v-if="isFetching" class="h-screen flex items-center justify-center">Loading...</div>

    <div v-else class="wrap">
      <div class="container">
        <RouterView />
      </div>
    </div>

    <ModalsContainer />

    <!-- <template v-else>
      <AppHeader />

      <div class="grid md:flex-1">
      </div>

      <AppFooter />
    </template> -->
  </div>
</template>

<style>
html,
body {
  height: 100%;
  background: #f5f5f5;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
}
</style>

<style scoped>
.wrap {
  min-height: 100%;
  height: auto;
  margin: 0 auto;
  padding-bottom: 2rem;
}

.container {
  max-width: 960px;
  min-width: 320px;
  padding: 0;
  margin-right: auto;
  margin-left: auto;
}
</style>
