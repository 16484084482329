import { ViteSSG } from 'vite-ssg'
import 'vue-final-modal/style.css'
import './assets/main.css'
import App from './App.vue'
import { routerOptions } from './router-options'
import { createVfm } from 'vue-final-modal'

export const createApp = ViteSSG(
  // the root component
  App,
  // vue-router options
  routerOptions,
  // function to have custom setups
  ({ app, router, routes, isClient, initialState }) => {
    const vfm = createVfm()
    app.use(vfm)
    // createPlugins(app)
  }
)
