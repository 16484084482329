<script lang="ts" setup>
import { onMounted } from 'vue'
import { RouterLink } from 'vue-router'
// import { createClient } from '@sanity/client'

// const artists = ref<any[]>([])

onMounted(async () => {
  // const client = createClient({
  //   projectId: 'zov9kujs',
  //   dataset: 'production',
  //   // useCdn: true, // set to `false` to bypass the edge cache
  //   // apiVersion: '2023-05-03', // use current date (YYYY-MM-DD) to target the latest API version
  //   // token: process.env.SANITY_SECRET_TOKEN // Only if you want to update content with the client
  //   token:
  //     'skdcszpol2LsTCDxeaLv3iDPnCLH12D1AEkh5LF5Yl3I0KewCEv3W1q9POiI1eN7YdkFxJ3jvAbz4OJuwAydyeaRxufG1haH5kzOrSNvqgCqCYWPzuXnh0FPaff01ZQQ1BG6HQIpP4SjnHgDZHRijnHJpMFNHKqG7KE1iyjfDHomoE5ic1iU'
  // })
  // artists.value = await client.fetch('*[_type == "artist"]')
})
</script>

<template>
  <div class="box">
    <h1 class="header">Литмобы</h1>
    <ul>
      <li><router-link class="link" to="/litmob/otvergnutie">Отвергнутые драконом</router-link></li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.box {
  margin-top: 5rem;
  padding: 2rem;
  background: #fff;
  border-radius: 1rem;
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.26);
  font-size: 1.1rem;

  li {
    padding-left: 1.5rem;
    position: relative;
    margin-bottom: 0.5rem;

    &::before {
      content: '•';
      left: -0.1rem;
      position: absolute;
      color: #cd1b1b;
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
  }
}

.header {
  font-size: 1.8rem;
  line-height: 2rem;
  margin-bottom: 1.2rem;
  font-weight: bold;
}

.link {
  &:hover {
    text-decoration: underline;
  }
}
</style>
