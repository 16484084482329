// import { createWebHistory } from 'vue-router'

import MainView from '@/views/MainView.vue'
import OtvergnutieLitmob from '@/views/OtvergnutieLitmob.vue'
import NotFoundView from '@/views/NotFoundView.vue'

export const routerOptions = {
  // history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: MainView
    },
    {
      path: '/litmob/otvergnutie',
      component: OtvergnutieLitmob
    },
    {
      path: '/:catchAll(.*)',
      component: NotFoundView
    }
  ]
}
